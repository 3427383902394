import React from "react";

import PhoneInput from "react-phone-input-2";

import "./style.css";

const PhoneNumber = ({ value, setValue }) => {
  return (
    <PhoneInput
      specialLabel={""}
      country={"sa"}
      value={value}
      onChange={(val) => setValue(val)}
    />
  );
};

export default PhoneNumber;
