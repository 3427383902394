import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const BasicTable = ({ clinicFilterData, fetchData }) => {
  const navigate = useNavigate();

  // url

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                // clinicId
                navigate(`/clinic-u-d-request/${cell._id}`);
              }}
            >
              <button
                className="btn btn-sm btn-success edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                Detail
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = clinicFilterData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.doctorInfo.fullName,
      email: data.doctorInfo.email,
      phone: data.doctorInfo.phoneNo,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
    </>
  );
};

export { BasicTable };
