import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import { createSelector } from "reselect";

import { BasicTable } from "./datatableCom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getDoctors } from "../../../store/actions";

const UserListPage = () => {
  document.title = "Doctors List";

  const [filterData, setFilterData] = useState([]);

  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getDoctors());
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      doctors: state.doctors,
    })
  );

  const { doctors } = useSelector(apisDataList);

  useEffect(() => {
    setFilterData(doctors);
  }, [doctors]);

  const filterList = (e) => {
    const newData = doctors.filter((item) =>
      item.doctor_data.fullName.includes(e.target.value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctors List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Name: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    doctorFilterData={filterData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserListPage;
