import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";

import { BasicTable } from "./datatableCom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const ClinicDataPage = () => {
  document.title = "Clinic Update Request";

  const [filterData, setFilterData] = useState([]);
  const [clinicData, setClinicData] = useState([]);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  // get lab test data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/admin/clinicUpdateRequest`
      );
      setClinicData(data);
      setFilterData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterList = (e) => {
    const newData = clinicData.filter((item) =>
      item.doctor_data.fullName.includes(e.target.value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctors List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Name: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    clinicFilterData={filterData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicDataPage;
