import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PhoneNumber from "../../../components/phoneNumber/PhoneNumber";
import OTPDialog from "./otpDialog/OTPDialog";

import logo from "../../../assets/images/logo-sm.png";

const RegisterPharmacyPage = () => {
  document.title = "Register Pharmacy Organization";

  // local state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [selectFile, setSelectFile] = useState(null);
  const [terms, setTerms] = useState(false);

  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [OTPNumber, setOTPNumber] = useState("");

  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // for taost message
  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  const handleUploadFile = (event) => {
    const files = event.target.files[0];
    setSelectFile(files);
  };

  const handlSubmit = async (e) => {
    e.preventDefault();

    const payloadData = {
      name,
      email,
      phoneNumber: `+${phoneNumber}`,
      address,
      password,
      terms,
    };

    try {
      setApiLoading(true);
      const data = await axios.post(
        `${process.env.REACT_APP_LIVESERVERURL}/api/pharmacy/auth/signup`,
        payloadData
      );
      if (data.status === 200) {
        setOTPNumber(data.data);
        setIsOTPModalOpen(true);
        settoast1(true);
        setToastMessage(data.message);
      }
      if (data.status === 202) {
        settoast1(true);
        setToastMessage(data.message);
      }
    } catch (err) {
      const errSplit = err.split(" ");
      const statusCode = errSplit[errSplit.length - 1];
      if (statusCode === "404") {
        setToastMessage("Something Went Wrong, Try Again");
        settoast1(true);
      }
      if (statusCode === "422") {
        setToastMessage("Please Pass Correct Values");
        settoast1(true);
      }
    }
    setApiLoading(false);
    // settoast1(true);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Pharmacy"
            breadcrumbItem="Register Organization"
          />

          <OTPDialog
            isModalOpen={isOTPModalOpen}
            setIsModalOpen={setIsOTPModalOpen}
            stateValue={{
              name,
              email,
              phoneNumber: `+${phoneNumber}`,
              address,
              password,
              OTPNumber,
              selectFile,
            }}
          />

          <Row>
            <Col>
              <Card className="px-5">
                <CardBody>
                  <Form onSubmit={handlSubmit}>
                    <CardTitle className="h4">
                      Register Pharmacy Organiztion With Email Authentication
                      ...
                    </CardTitle>
                    <p className="card-title-desc"></p>

                    <Row className="mb-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Organiztion Name :
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Organization Name *"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-email-input"
                        className="col-md-2 col-form-label"
                      >
                        Organiztion Email :
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter Your Email *"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Password :
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Enter Your Password *"
                          min={6}
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-phone-input"
                        className="col-md-2 col-form-label"
                      >
                        Phone Number :
                      </label>
                      <div className="col-md-10">
                        <PhoneNumber
                          value={phoneNumber}
                          setValue={setPhoneNumber}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Address :
                      </label>
                      <div className="col-md-10">
                        <textarea
                          className="form-control"
                          placeholder="Enter Your Address *"
                          rows="5"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Image :
                      </label>
                      <div className="col-md-10">
                        <input
                          type="file"
                          className="form-control"
                          placeholder="Select Your Image"
                          onChange={handleUploadFile}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      ></label>
                      <div className="col-md-10 ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="invalidCheck"
                          value={terms}
                          onChange={() => setTerms(!terms)}
                          required
                        />
                        <label
                          className="form-check-label mx-2"
                          htmlFor="invalidCheck"
                        >
                          {" "}
                          I Agree to all Terms and Services
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <div className="col-10 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          disabled={apiLoading}
                        >
                          Register User
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegisterPharmacyPage;
