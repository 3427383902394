import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row, Container, CardBody } from "reactstrap";

import { useParams } from "react-router-dom";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { BasicTable } from "./datatableCom";

const DriverListPage = () => {
  document.title = "Amnulance Drivers Data";

  const [driverData, setDriverData] = useState([]);

  const params = useParams();

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  //
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/admin/ambulanceOrgDrivers/${params.id}`
      );

      setDriverData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Drivers"
            breadcrumbItem="Ambulance Organization Drivers"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <BasicTable
                    ambulanceDriversData={driverData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DriverListPage;
