import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import { createSelector } from "reselect";

import { BasicTable } from "./DataTables/datatableCom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getCategory } from "../../../store/actions";

const CategoryListPage = () => {
  document.title = "Categories List";

  const [filterData, setFilterData] = useState([]);

  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getCategory());
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      category: state.category,
    })
  );

  const { category } = useSelector(apisDataList);

  useEffect(() => {
    setFilterData(category);
  }, [category]);

  const filterList = (e) => {
    const value = e.target.value.toLowerCase();
    const newData = category.filter((item) =>
      item.title.toLowerCase().includes(value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Category" breadcrumbItem="Categories List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Title: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    categoryApiData={filterData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CategoryListPage;
