import {
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAIL,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAIL,
  GET_AMBULANCE_ORG_SUCCESS,
  GET_AMBULANCE_ORG_FAIL,
  GET_LABORATORY_ORG_SUCCESS,
  GET_LABORATORY_ORG_FAIL,
  GET_PHARMACY_ORG_SUCCESS,
  GET_PHARMACY_ORG_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_SUB_CATEGORY_SUCCESS,
  GET_SUB_CATEGORY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  banners: [],
  bannerError: "",
  doctors: [],
  doctorError: "",
  patients: [],
  patientError: "",
  ambulanceOrg: [],
  ambulanceOrgError: "",
  laboratoryOrg: [],
  laboratoryOrgError: "",
  pharmacyOrg: [],
  pharmacyOrgError: "",
  category: [],
  categoryError: "",
  subCategory: [],
  subCategoryError: "",
};

const ApisData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
      };
    case GET_BANNERS_FAIL:
      return {
        ...state,
        bannerError: action.payload,
      };

    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: action.payload,
      };
    case GET_DOCTORS_FAIL:
      return {
        ...state,
        doctorError: action.payload,
      };

    case GET_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      };
    case GET_PATIENT_FAIL:
      return {
        ...state,
        patientError: action.payload,
      };

    case GET_AMBULANCE_ORG_SUCCESS:
      return {
        ...state,
        ambulanceOrg: action.payload,
      };
    case GET_AMBULANCE_ORG_FAIL:
      return {
        ...state,
        ambulanceOrgError: action.payload,
      };

    case GET_LABORATORY_ORG_SUCCESS:
      return {
        ...state,
        laboratoryOrg: action.payload,
      };
    case GET_LABORATORY_ORG_FAIL:
      return {
        ...state,
        laboratoryOrgError: action.payload,
      };

    case GET_PHARMACY_ORG_SUCCESS:
      return {
        ...state,
        pharmacyOrg: action.payload,
      };
    case GET_PHARMACY_ORG_FAIL:
      return {
        ...state,
        pharmacyOrgError: action.payload,
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
      };
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        categoryError: action.payload,
      };

    case GET_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: action.payload,
      };
    case GET_SUB_CATEGORY_FAIL:
      return {
        ...state,
        subCategoryError: action.payload,
      };

    default:
      return state;
  }
};

export default ApisData;
