import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  Toast,
  ToastHeader,
  ToastBody,
  Col,
  Row,
} from "reactstrap";
import axios from "axios";

import logo from "../../../../assets/images/logo-sm.png";

const BasicTable = ({ subCategoryApiData, fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete Category
  const handleDeleteCategory = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/deleteSubCategory/${categoryId}`
      );
      if (data?.status === 200) {
        fetchData();
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Title</span>,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Category Title</span>,
      selector: (row) => row.categoryTitle,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (cell) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn"
                onClick={() => {
                  setCategoryId(cell._id);
                  setIsEditModalOpen(!isEditModalOpen);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem
                className="remove-item-btn"
                onClick={() => {
                  setCategoryId(cell._id);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const data2 = subCategoryApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      title: data.title,
      categoryTitle: data.categoryTitle,
      assigned: data.imgUrl,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <DataTable columns={columns} data={data2} pagination />
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Are You Sure to Delete This Category?
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDeleteCategory}
          >
            Yes
          </button>
        </div>
      </Modal>

      {/* Update Sub Category */}
      {isEditModalOpen && (
        <EditSubCategory
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          apiRoute={apiRoute}
          categoryId={categoryId}
          setToastMessage={setToastMessage}
          settoast1={settoast1}
          categoryApiData={subCategoryApiData}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

const EditSubCategory = ({
  isEditModalOpen,
  setIsEditModalOpen,
  apiRoute,
  categoryId,
  setToastMessage,
  settoast1,
  categoryApiData,
  fetchData,
}) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const singleData = categoryApiData.filter(
      (data) => data._id === categoryId
    );
    setTitle(singleData[0].title);
  }, [categoryApiData, categoryId]);

  const handleUpdateSubCategory = async () => {
    try {
      const data = await axios.put(
        `${apiRoute}/api/admin/updateSubCategory/${categoryId}`,
        {
          title,
        }
      );
      if (data?.status === 200) {
        setToastMessage(data.message);
        settoast1(true);
        fetchData();
        setIsEditModalOpen(false);
      }
    } catch (error) {
      const errSplit = error.split(" ");
      const statusCode = errSplit[errSplit.length - 1];
      if (statusCode === "404") {
        setError("This category title already exists");
      }
    }
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => {
        setIsEditModalOpen(!isEditModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update Data</h5>
      </div>
      <div className="modal-body">
        <Col>
          <Row className="">
            <label className="col-md-2"></label>
            <div className="col-md-10 text-danger">{error && error}</div>
          </Row>
          <Row className="my-2">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Title :
            </label>
            <div className="col-md-10">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Title *"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </Row>
        </Col>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            setIsEditModalOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdateSubCategory}
        >
          Update
        </button>
      </div>
    </Modal>
  );
};

export { BasicTable };
