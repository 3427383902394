import { useState } from "react";
// import { createSelector } from "reselect";
// import { useSelector } from "react-redux";

import { getLoggedinUser } from "../helpers/api_helper";

const useProfile = () => {
  const userProfileSession = getLoggedinUser();

  // const loginpage = createSelector(
  //   (state) => state.login,
  //   (state) => ({
  //     user: state.user,
  //   })
  // );

  // const { user } = useSelector(loginpage);

  // console.log("user", user);

  // const userProfileSession = user;

  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  return { userProfile, loading };
};

export { useProfile };
