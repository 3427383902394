import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";

import OtpInput from "react-otp-input";

import logo from "../../../../assets/images/logo-sm.png";
import "./optDialog.style.css";

const OTPDialog = ({ isModalOpen, setIsModalOpen, stateValue }) => {
  const navigate = useNavigate();

  const [otpInputValue, setOtpInputValue] = useState("");
  const [apiLoading, setApiLoading] = useState(false);

  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  const handleVerifyOTP = async () => {
    setApiLoading(true);
    if (otpInputValue === "" || otpInputValue === null) {
      setToastMessage("Please write OTP Code!");
      settoast1(true);
      setApiLoading(false);
      return;
    } else {
      const { name, email, phoneNumber, address, password, OTPNumber } =
        stateValue;
      if (otpInputValue === OTPNumber) {
        try {
          const payloadData = {
            name,
            email,
            phoneNumber,
            address,
            password,
          };
          const data = await axios.post(
            `${process.env.REACT_APP_LIVESERVERURL}/api/ambulance/organization/verifyOTP`,
            payloadData
          );

          if (data.status === 201) {
            setToastMessage(data.message);
            setIsModalOpen(false);
            navigate("/ambulance-organization-list");
          }
        } catch (err) {
          const errSplit = err.split(" ");
          const statusCode = errSplit[errSplit.length - 1];
          if (statusCode === "400") {
            setToastMessage("Something Went Wrong, Try Again");
          }
        }
      } else {
        setToastMessage("OTP Code is not Correct");
        settoast1(true);
      }
    }
    setApiLoading(false);
    settoast1(true);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Enter OTP Code</h5>
        </div>
        <div className="modal-body">
          <div className="mb-3 opt-style">
            <OtpInput
              value={otpInputValue}
              onChange={setOtpInputValue}
              numInputs={4}
              renderSeparator={<span>-</span>}
              inputStyle={{
                width: "60px",
                height: "50px",
                fontSize: "20px",
                margin: "10px",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            disabled={apiLoading}
            onClick={handleVerifyOTP}
          >
            VERIFY CODE
          </button>
        </div>
      </Modal>
    </>
  );
};

export default OTPDialog;
