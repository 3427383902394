import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_BANNERS,
  GET_DOCTORS,
  GET_PATIENT,
  GET_AMBULANCE_ORG,
  GET_LABORATORY_ORG,
  GET_PHARMACY_ORG,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
} from "./actionTypes";
import {
  getBannersSuccess,
  getBannersFail,
  getDoctorsSuccess,
  getDoctorsFail,
  getPatientsSuccess,
  getPatientsFail,
  getAmbulanceOrgSuccess,
  getAmbulanceOrgFail,
  getLaboratoryOrgSuccess,
  getLaboratoryOrgFail,
  getPharmacyOrgSuccess,
  getPharmacyOrgFail,
  getCategorySuccess,
  getCategoryFail,
  getSubCategorySuccess,
  getSubCategoryFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getBanners,
  getDoctors,
  getPatients,
  getAmbulanceOrgData,
  getLaboratoryOrgData,
  getPharmacyOrgData,
  getCategoryData,
  getSubCategoryData,
} from "../../helpers/fakebackend_helper";

function* fetchBanners() {
  try {
    const response = yield call(getBanners);
    yield put(getBannersSuccess(response.data));
  } catch (error) {
    yield put(getBannersFail(error));
  }
}

function* fetchDoctors() {
  try {
    const response = yield call(getDoctors);
    yield put(getDoctorsSuccess(response.data));
  } catch (error) {
    yield put(getDoctorsFail(error));
  }
}

function* fetchPatients() {
  try {
    const response = yield call(getPatients);
    yield put(getPatientsSuccess(response.data));
  } catch (error) {
    yield put(getPatientsFail(error));
  }
}

function* fetchAmbulanceOrg() {
  try {
    const response = yield call(getAmbulanceOrgData);
    yield put(getAmbulanceOrgSuccess(response.data));
  } catch (error) {
    yield put(getAmbulanceOrgFail(error));
  }
}

function* fetchLaboratoryOrg() {
  try {
    const response = yield call(getLaboratoryOrgData);
    yield put(getLaboratoryOrgSuccess(response.data));
  } catch (error) {
    yield put(getLaboratoryOrgFail(error));
  }
}

function* fetchPharmacyOrg() {
  try {
    const response = yield call(getPharmacyOrgData);
    yield put(getPharmacyOrgSuccess(response.data));
  } catch (error) {
    yield put(getPharmacyOrgFail(error));
  }
}

function* fetchCategory() {
  try {
    const response = yield call(getCategoryData);
    yield put(getCategorySuccess(response.data));
  } catch (error) {
    yield put(getCategoryFail(error));
  }
}

function* fetchSubCategory() {
  try {
    const response = yield call(getSubCategoryData);
    yield put(getSubCategorySuccess(response.data));
  } catch (error) {
    yield put(getSubCategoryFail(error));
  }
}

function* apisDataSaga() {
  yield takeEvery(GET_BANNERS, fetchBanners);
  yield takeEvery(GET_DOCTORS, fetchDoctors);
  yield takeEvery(GET_PATIENT, fetchPatients);
  yield takeEvery(GET_AMBULANCE_ORG, fetchAmbulanceOrg);
  yield takeEvery(GET_LABORATORY_ORG, fetchLaboratoryOrg);
  yield takeEvery(GET_PHARMACY_ORG, fetchPharmacyOrg);
  yield takeEvery(GET_CATEGORY, fetchCategory);
  yield takeEvery(GET_SUB_CATEGORY, fetchSubCategory);
}

export default apisDataSaga;
