import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Banner Pages
import CreateBannerPage from "../Pages/Banner/CreateBanner-Page.js";
import BannerListPage from "../Pages/Banner/BannerList-Page.js";

// Import Doctor
import DoctorListPage from "../Pages/Doctor/DoctorData/DoctorList-Page.js";
import ClinicDataPage from "../Pages/Doctor/ClinicData/ClinicData-Page.js";
import ClinicSingleDataPage from "../Pages/Doctor/ClinicData/ClinicSingleData/ClinicSingleData-Page.js";
// Import Category Pages
import CreateCategoryPage from "../Pages/Doctor/Category/CreateCategory-Page.js";
import CategoryListPage from "../Pages/Doctor/Category/CategoryList-Page.js";
// Import Sub Category Pages
import CreateSubCategoryPage from "../Pages/Doctor/SubCategory/CreateSubCategory-Page.js";
import SubCategoryListPage from "../Pages/Doctor/SubCategory/SubCategoryList-Page.js";

// users
import PatientListPage from "../Pages/Users/PatientData/PatientList-Page.js";

// Import Lab Test Pages
import CreateLabTestPage from "../Pages/LabTest/CreateLabTest-Page.js";
import LabTestListPage from "../Pages/LabTest/LabTestList-Page.js";

// Import Ambulance Organization Pages
import RegisterAmbulancePage from "../Pages/AmbulanceOrg/RegisterAmbulanceOrg/RegisterAmbulance-Page.js";
import AmbulanceListPage from "../Pages/AmbulanceOrg/AmbulanceOrgList/AmbulanceList-Page.js";

// Import Laboratory Organization Pages
import RegisterLaboratoryPage from "../Pages/LaboratoryOrg/RegisterLaboratoryOrg/RegisterLaboratory-Page.js";
import LaboratoryListPage from "../Pages/LaboratoryOrg/LaboratoryOrgList/LaboratoryList-Page.js";

// Import Pharmacy Organization Pages
import RegisterPharmacyPage from "../Pages/pharmacy/RegisterPharmacyOrg/RegisterPharmacy-Page.js";
import PharmacyListPage from "../Pages/pharmacy/PharmacyOrgList/PharmacyList-Page.js";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import UserProfile from "../Pages/Authentication/user-profile";

import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";
import DriverListPage from "../Pages/AmbulanceOrg/AmbulanceOrgList/driverList/DriverList-Page.js";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // Banner Pages
  { path: "/create-banner", component: <CreateBannerPage /> },
  { path: "/banner-list", component: <BannerListPage /> },

  // Doctor Pages
  { path: "/doctor-list", component: <DoctorListPage /> },
  // Clinic Request Page
  { path: "/clinic-u-d-request", component: <ClinicDataPage /> },
  { path: "/clinic-u-d-request/:id", component: <ClinicSingleDataPage /> },
  // Categoy Pages
  { path: "/create-category", component: <CreateCategoryPage /> },
  { path: "/category-list", component: <CategoryListPage /> },
  // Sub Categoy Pages
  { path: "/create-sub-category", component: <CreateSubCategoryPage /> },
  { path: "/sub-category-list", component: <SubCategoryListPage /> },

  // users
  { path: "/patient-list", component: <PatientListPage /> },

  // Ambulance Organization Pages
  {
    path: "/register-ambulance-organization",
    component: <RegisterAmbulancePage />,
  },
  { path: "/ambulance-organization-list", component: <AmbulanceListPage /> },
  {
    path: "/ambulance-organization-drivers/:id",
    component: <DriverListPage />,
  },

  // Laboratory Organization Pages
  {
    path: "/register-laboratory-organization",
    component: <RegisterLaboratoryPage />,
  },
  { path: "/laboratory-organization-list", component: <LaboratoryListPage /> },

  // Pharmacy Organization Pages
  {
    path: "/register-pharmacy-organization",
    component: <RegisterPharmacyPage />,
  },
  { path: "/pharmacy-organization-list", component: <PharmacyListPage /> },

  // Lab Test Pages
  { path: "/create-lab-test", component: <CreateLabTestPage /> },
  { path: "/lab-test-list", component: <LabTestListPage /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Authentication Inner Pages

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
