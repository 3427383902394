import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import logo from "../../assets/images/logo-sm.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const optionGroup = [
  {
    label: "Type",
    options: [
      { label: "User", value: "user" },
      { label: "Laboratory", value: "laboratory" },
      { label: "Pharmacy", value: "pharmacy" },
    ],
  },
];

const CreateBannerPage = () => {
  document.title = "Create Banner";

  const navigate = useNavigate();
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [selectedGroup, setselectedGroup] = useState(null);

  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [active, setActive] = useState(false);
  const [selectFile, setSelectFile] = useState();
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  const handleUploadFile = (event) => {
    const files = event.target.files[0];
    setSelectFile(files);
  };

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title !== "" && desc !== "" && selectFile.length !== null) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("type", type);
        formData.append("title", title);
        formData.append("description", desc);
        formData.append("bannerImage", selectFile);
        formData.append("active", active);

        const response = await axios.post(
          `${apiRoute}/api/admin/createBanner`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response, "response");
        if (response.status === 201) {
          setToastMessage(response.message);
          setTimeout(() => {
            navigate("/banner-list");
          }, 1500);
        }
      } catch (err) {
        const errSplit = err.split(" ");
        const statusCode = errSplit[errSplit.length - 1];
        if (statusCode === "400") {
          setToastMessage("Image file does not saved,try again");
        } else if (statusCode === "409") {
          setToastMessage("This banner title name already exits");
        }
      }
      setLoading(false);
      settoast1(true);
    } else {
      setToastMessage("Please fill all the fields");
    }
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Banner" breadcrumbItem="Create Banner" />
          <Row>
            <Col>
              <Card className="px-5">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row className="my-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Type :
                      </label>
                      <div className="col-md-10">
                        <Select
                          value={selectedGroup}
                          onChange={(e) => {
                            setType(e.value);
                            handleSelectGroup(e);
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                          required
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title :
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Title *"
                          required
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Description :
                      </label>
                      <div className="col-md-10">
                        <textarea
                          required
                          className="form-control"
                          placeholder="Enter Your Description *"
                          rows="5"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Image :
                      </label>
                      <div className="col-md-10">
                        <input
                          type="file"
                          className="form-control"
                          placeholder="Select Your Image *"
                          required
                          onChange={handleUploadFile}
                        />
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      ></label>
                      <div className="col-md-10 ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="invalidCheck"
                          value={active}
                          onChange={() => setActive(!active)}
                        />
                        <label
                          className="form-check-label mx-2"
                          htmlFor="invalidCheck"
                        >
                          {" "}
                          Check to display banner image on screen
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <div className="col-10 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateBannerPage;
