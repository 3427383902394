const SidebarData = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    url: "/dashboard",
  },
  {
    label: "Banner",
    icon: "mdi mdi-format-page-break",
    subItem: [
      { sublabel: "Create Banner", link: "/create-banner" },
      { sublabel: "Banner List", link: "/banner-list" },
    ],
  },
  {
    label: "Doctor",
    icon: "mdi mdi-format-page-break",
    subItem: [
      { sublabel: "Doctor List", link: "/doctor-list" },
      { sublabel: "Clinic U-D Request", link: "/clinic-u-d-request" },
      { sublabel: "Create Category", link: "/create-category" },
      { sublabel: "Category List", link: "/category-list" },
      { sublabel: "Create Sub Category", link: "/create-sub-category" },
      { sublabel: "Sub Category List", link: "/sub-category-list" },
    ],
  },
  {
    label: "Users",
    icon: "mdi mdi-format-page-break",
    subItem: [{ sublabel: "Patient List", link: "/patient-list" }],
  },
  {
    label: "Ambulance",
    icon: "mdi mdi-format-page-break",
    subItem: [
      {
        sublabel: "Create Organization",
        link: "/register-ambulance-organization",
      },
      { sublabel: "Organization List", link: "/ambulance-organization-list" },
    ],
  },
  {
    label: "Laboratory",
    icon: "mdi mdi-format-page-break",
    subItem: [
      {
        sublabel: "Create Organization",
        link: "/register-laboratory-organization",
      },
      { sublabel: "Organization List", link: "/laboratory-organization-list" },
      { sublabel: "Create Lab Test", link: "/create-lab-test" },
      { sublabel: "Lab Test List", link: "/lab-test-list" },
    ],
  },
  {
    label: "Pharmacy",
    icon: "mdi mdi-format-page-break",
    subItem: [
      {
        sublabel: "Create Organization",
        link: "/register-pharmacy-organization",
      },
      { sublabel: "Organization List", link: "/pharmacy-organization-list" },
    ],
  },
];
export default SidebarData;
