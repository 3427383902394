import React from "react";
import DataTable from "react-data-table-component";

const BasicTable = ({ ambulanceDriversData, fetchData }) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => row.address,
      sortable: true,
    },
  ];

  const data2 = ambulanceDriversData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      address: data.address,
    };
    return obj;
  });

  return (
    <>
      {/* // All table data start */}
      <DataTable columns={columns} data={data2} pagination />
      {/* // All table data end */}
    </>
  );
};

export { BasicTable };
