import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

// import RadialChart1 from "./userpanelChart1";
// import RadialChart2 from "./userpanelChart2";
// import RadialChart3 from "./userpanelChart3";

import {
  getBanners,
  getDoctors,
  getPatients,
  getAmbulanceOrg,
  getLaboratoryOrg,
  getPharmacyOrg,
  getCategory,
  getSubCategory,
} from "../../store/actions";

const UserPanel = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanners());
    dispatch(getDoctors());
    dispatch(getPatients());
    dispatch(getAmbulanceOrg());
    dispatch(getLaboratoryOrg());
    dispatch(getPharmacyOrg());
    dispatch(getCategory());
    dispatch(getSubCategory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      banners: state.banners,
      // bannerError: state.bannerError,
      doctors: state.doctors,
      // doctorError: state.doctorError,
      patients: state.patients,
      // patientError: state.patientError,
      ambulanceOrg: state.ambulanceOrg,
      // ambulanceOrgError: state.ambulanceOrgError,
      laboratoryOrg: state.laboratoryOrg,
      // laboratoryOrgError: state.laboratoryOrgError,
      pharmacyOrg: state.pharmacyOrg,
      // pharmacyOrgError: state.pharmacyOrgError,
      category: state.category,
      // categoryError: state.categoryError,
      subCategory: state.subCategory,
      // subCategoryError: state.subCategoryError,
    })
  );

  const {
    banners,
    doctors,
    patients,
    ambulanceOrg,
    laboratoryOrg,
    pharmacyOrg,
    category,
    subCategory,
  } = useSelector(apisDataList);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Banners</p>
                  <h5 className="mb-3">
                    {banners.length > 0 ? banners.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-user-circle"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Doctors</p>
                  <h5 className="mb-3">
                    {doctors.length > 0 ? doctors.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-user-circle"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Patients</p>
                  <h5 className="mb-3">
                    {patients.length > 0 ? patients.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Ambulance Organizations</p>
                  <h5 className="mb-3">
                    {ambulanceOrg.length > 0 ? ambulanceOrg.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Laboratory Organizations</p>
                  <h5 className="mb-3">
                    {laboratoryOrg.length > 0 ? laboratoryOrg.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Pharmacy Organizations</p>
                  <h5 className="mb-3">
                    {pharmacyOrg.length > 0 ? pharmacyOrg.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Doctor Categories</p>
                  <h5 className="mb-3">
                    {category.length > 0 ? category.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-minus-back"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Doctor Sub-Categories</p>
                  <h5 className="mb-3">
                    {subCategory.length > 0 ? subCategory.length : 0}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
