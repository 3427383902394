import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import { createSelector } from "reselect";

import { BasicTable } from "./datatableCom";
import { getLaboratoryOrg } from "../../../store/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const LaboratoryListPage = () => {
  document.title = "Laboratory Organizations List";

  const [filterData, setFilterData] = useState([]);

  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getLaboratoryOrg());
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apisDataList = createSelector(
    (state) => state.apisData,
    (state) => ({
      laboratoryOrg: state.laboratoryOrg,
    })
  );

  const { laboratoryOrg } = useSelector(apisDataList);

  useEffect(() => {
    setFilterData(laboratoryOrg);
  }, [laboratoryOrg]);

  const filterList = (e) => {
    const newData = laboratoryOrg.filter((item) =>
      item.name.includes(e.target.value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Laboratory" breadcrumbItem="Organization List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Name: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    laboratoryOrgFilterData={filterData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LaboratoryListPage;
