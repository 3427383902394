import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Col, Row, Container, Modal } from "reactstrap";

import { useParams, useNavigate } from "react-router-dom";

import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const ClinicSingleDataPage = () => {
  document.title = "Clinic Single Data";

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [clinicSingleData, setClinicSingleData] = useState([]);
  const [bookedAppointmentData, setBookedAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  //
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/admin/singleClinicUpdateRequest/${params.id}`
      );

      setClinicSingleData(data.clinicUpdateInfo);
      setBookedAppointmentData(data.bookedAppointmentInfo);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateData = async (id) => {
    setLoading(true);
    try {
      const data = await axios.put(
        `${apiRoute}/api/admin/singleClinicUpdateRequest/${id}`
      );
      if (data.status === 200) {
        navigate("/clinic-u-d-request");
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClinic = async (id) => {
    setLoading(true);
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/singleClinicDeleteRequest/${id}`
      );
      if (data.status === 200) {
        navigate("/clinic-u-d-request");
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelRequest = async (id) => {
    setLoading(true);
    try {
      const data = await axios.delete(
        `${apiRoute}/api/admin/singleClinicCancelRequest/${id}`
      );
      if (data.status === 200) {
        navigate("/clinic-u-d-request");
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Clinic"
            breadcrumbItem="Single Clinic Update OR Delete Request"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Row>
                  <Col lg={6} className="border-end">
                    <div
                      className="mt-3 d-flex flex-column px-3"
                      style={{ fontSize: "15px" }}
                    >
                      <h5 className="border-bottom pb-1">
                        Clinic Current Details
                      </h5>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Name: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.name}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Country: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.country}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">City: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.city}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">address: </span>
                        <span className="w-75">
                          {
                            clinicSingleData[0]?.clinicCurrentDetails
                              .clinicLocation
                          }
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Country Code: </span>
                        <span className="w-75">
                          {
                            clinicSingleData[0]?.clinicCurrentDetails
                              .countryCode
                          }
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Country: </span>
                        <span className="w-75">
                          {
                            clinicSingleData[0]?.clinicCurrentDetails
                              .countryFlag
                          }
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Phone No: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.phone}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Landline No: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.landLine}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Assistant No: </span>
                        <span className="w-75">
                          {
                            clinicSingleData[0]?.clinicCurrentDetails
                              .assistantNumber
                          }
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Fee: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.fee}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Currency: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.currency}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Lattitude: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.lat}
                        </span>
                      </p>
                      <p className="d-flex">
                        <span className="fw-bold w-25">Longitude: </span>
                        <span className="w-75">
                          {clinicSingleData[0]?.clinicCurrentDetails.long}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    {clinicSingleData[0]?.clinicCurrentDetails.status ===
                    "update" ? (
                      <div
                        className="mt-3 d-flex flex-column px-3"
                        style={{ fontSize: "15px" }}
                      >
                        <h5 className="border-bottom pb-1">
                          Updated Request Details
                        </h5>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Name: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.name}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Country: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.country}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">City: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.city}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">address: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.clinicLocation}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Country Code: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.countryCode}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Country: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.countryFlag}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Phone No: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.phone}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Landline No: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.landLine}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Assistant No: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.assistantNumber}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Fee: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.fee}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Currency: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.currency}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Lattitude: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.lat}
                          </span>
                        </p>
                        <p className="d-flex">
                          <span className="fw-bold w-25">Longitude: </span>
                          <span className="w-75">
                            {clinicSingleData[0]?.long}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="h-100 d-flex flex-column gap-3 align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-danger px-5"
                          disabled={loading}
                          onClick={() => {
                            setIsDeleteModalOpen(!isDeleteModalOpen);
                          }}
                        >
                          Delete Clinic
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary px-5"
                          disabled={loading}
                          onClick={() => {
                            setIsCancelModalOpen(!isCancelModalOpen);
                            // handleDeleteData(clinicSingleData[0]?._id);
                          }}
                        >
                          Cancel Request
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
                {clinicSingleData[0]?.clinicCurrentDetails.status ===
                  "update" && (
                  <Row className="m-4">
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary px-5"
                        disabled={loading}
                        onClick={() =>
                          handleUpdateData(clinicSingleData[0]?._id)
                        }
                      >
                        Update data
                      </button>
                    </div>
                  </Row>
                )}
              </Card>
              <Card>
                <Row>
                  <h3 className="text-center mt-3">
                    Upcoming Booked Appointment
                  </h3>
                </Row>
                {bookedAppointmentData.length > 0 &&
                  bookedAppointmentData.map((bookedData, index) => (
                    <Row key={bookedData._id}>
                      <h5 className="text-center mt-2">{index + 1}</h5>
                      <Col lg={6} className="border-end">
                        <div
                          className="mt-3 d-flex flex-column px-3"
                          style={{ fontSize: "15px" }}
                        >
                          <h5 className="border-bottom pb-1">
                            Appointment Detail
                          </h5>
                          <p className="d-flex">
                            <span className="fw-bold w-50">
                              Appointment Date:{" "}
                            </span>
                            <span className="w-50">
                              {bookedData.bookedDate}
                            </span>
                          </p>
                          <p className="d-flex">
                            <span className="fw-bold w-50">
                              Appointment Start Time:{" "}
                            </span>
                            <span className="w-50">{bookedData.startTime}</span>
                          </p>
                          <p className="d-flex">
                            <span className="fw-bold w-50">
                              Appointment End Time:{" "}
                            </span>
                            <span className="w-50">{bookedData.endTime}</span>
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className="mt-3 d-flex flex-column px-3"
                          style={{ fontSize: "15px" }}
                        >
                          <h5 className="border-bottom pb-1">Patient Info</h5>
                          <p className="d-flex">
                            <span className="fw-bold w-25">Name: </span>
                            <span className="w-75">
                              {bookedData.patientInfo.fullName}
                            </span>
                          </p>
                          <p className="d-flex">
                            <span className="fw-bold w-25">Email: </span>
                            <span className="w-75">
                              {bookedData.patientInfo.email}
                            </span>
                          </p>
                          <p className="d-flex">
                            <span className="fw-bold w-25">Phone Number: </span>
                            <span className="w-75">
                              {bookedData.patientInfo.phoneNo}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Card>
            </Col>
          </Row>
        </Container>

        {/* delete clinic data */}
        <Modal
          isOpen={isDeleteModalOpen}
          toggle={() => {
            setIsDeleteModalOpen(!isDeleteModalOpen);
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Are You Sure to Delete This Clinic?
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading}
              onClick={() => handleDeleteClinic(clinicSingleData[0]?._id)}
            >
              Yes
            </button>
          </div>
        </Modal>

        {/* cancel clinic request */}
        <Modal
          isOpen={isCancelModalOpen}
          toggle={() => {
            setIsCancelModalOpen(!isCancelModalOpen);
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Are You Sure to Cancel This Clinic Request?
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsCancelModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsCancelModalOpen(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading}
              onClick={() => handleCancelRequest(clinicSingleData[0]?._id)}
            >
              Yes
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ClinicSingleDataPage;
