import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import axios from "axios";

import { BasicTable } from "./DataTables/datatableCom";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const LabTestListPage = () => {
  document.title = "Lab Test List";

  const [labTestApiData, setLabTestApiData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  // get lab test data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${apiRoute}/api/admin/allLabTests`);
      setLabTestApiData(data);
      setFilterData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterList = (e) => {
    const newData = labTestApiData.filter((item) =>
      item.title.includes(e.target.value)
    );
    setFilterData(newData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Laboratory" breadcrumbItem="Test List" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex gap-2">
                    <label className="mt-2">Search by Title: </label>
                    <div className="position-relative bg-light">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => filterList(e)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <BasicTable
                    labTestfilterData={filterData}
                    fetchData={fetchData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LabTestListPage;
