import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

import logo from "../../assets/images/logo-sm.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CreateLabTestPage = () => {
  document.title = "Create Lab Test";

  const navigate = useNavigate();
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const [title, setTitle] = useState("");
  const [dataFile, setDataFile] = useState([]);
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadOption, setUploadOption] = useState("single");

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (uploadOption === "single") {
      if (title !== "") {
        try {
          setLoading(true);

          const response = await axios.post(
            `${apiRoute}/api/admin/createLabTest`,
            { title: title }
          );
          if (response.status === 201) {
            setToastMessage(response.message);
            setTimeout(() => {
              navigate("/lab-test-list");
            }, 1500);
          }
        } catch (err) {
          const errSplit = err.split(" ");
          const statusCode = errSplit[errSplit.length - 1];
          if (statusCode === "409") {
            setToastMessage("This Lab Test already exits");
          }
        }
        setLoading(false);
        settoast1(true);
      } else {
        setToastMessage("Please Enter Some Title");
      }
    } else {
      if (dataFile.length > 0) {
        try {
          setLoading(true);

          const response = await axios.post(
            `${apiRoute}/api/admin/labTestFileUpload`,
            { dataFile: dataFile }
          );
          if (response.status === 201) {
            setToastMessage(response.message);
            setTimeout(() => {
              navigate("/lab-test-list");
            }, 1500);
          }
        } catch (err) {
          const errSplit = err.split(" ");
          const statusCode = errSplit[errSplit.length - 1];
          if (statusCode === "400") {
            setToastMessage("All Lab Tests, Already in Database");
          }
        }
        setLoading(false);
        settoast1(true);
      } else {
        setToastMessage("File has no data in it");
      }
    }
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setDataFile(sheetData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSampleFile = () => {
    const data = [
      { "Test Name": "CBC" },
      { "Test Name": "URIC ACID" },
      { "Test Name": "LIPID PROFILE" },
      { "Test Name": "CHOLESTEROL" },
      { "Test Name": "COMPLETE BLOOD" },
    ];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert the JSON data to a worksheet

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate an Excel file and download it
    XLSX.writeFile(workbook, "sample-data.xlsx"); // This triggers the download
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Laboratory" breadcrumbItem="Create Test" />
          <Row>
            <Col>
              <Card className="px-5">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-4">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Select Option :
                      </label>
                      <div className="col-md-10 d-flex align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadios"
                            id="inlineRadios1"
                            defaultValue="option1"
                            defaultChecked={true}
                            onChange={() => {
                              setUploadOption("single");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadios1"
                          >
                            Add Single Test
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadios"
                            id="inlineRadios2"
                            defaultValue="option2"
                            onChange={() => {
                              setUploadOption("multiple");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadios2"
                          >
                            Add Multiple Test
                          </label>
                        </div>
                      </div>
                    </Row>
                    {uploadOption === "single" ? (
                      <>
                        <Row className="mb-4">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Title :
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Title *"
                              required
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </Row>
                        <Row className="mb-4">
                          <div className="col-10 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary px-5"
                              disabled={loading}
                            >
                              Create Lab Test
                            </button>
                          </div>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>Upload Via Excel File</h5>
                            <h6>
                              Download Sample File:{" "}
                              <button
                                className="btn btn-secondary px-2"
                                onClick={handleSampleFile}
                              >
                                Download
                              </button>
                            </h6>
                          </div>
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Select File :
                          </label>
                          <div className="col-md-10">
                            <input
                              type="file"
                              className="form-control"
                              placeholder="Select Your File *"
                              required
                              onChange={handleUploadFile}
                            />
                          </div>
                        </Row>
                        <Row className="mb-4">
                          <div className="col-10 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary px-5"
                              disabled={loading}
                            >
                              Upload
                            </button>
                          </div>
                        </Row>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateLabTestPage;
